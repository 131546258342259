import { Box } from "@mui/material";
import { BiSolidLeftArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import pageSwitchLink from "./PageSwitch";

function LeftArrow({title, link}) {
  return (<>
    <Link to={`/${link}`} style={pageSwitchLink}>
      <Box style={{display: "flex", alignItems: "center"}}>
        <BiSolidLeftArrow size={20}/><span style={{ fontSize: '25px', marginBottom: '2px', marginLeft: '20px'}}>{title}</span>
      </Box>
    </Link>
  </>)
}

export default LeftArrow;