import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import {
  Box,
} from '@mui/material';
import { AnimatePresence} from "framer-motion";

import Seat from './Components/ProjectCards/Seat';
import Table from './Components/ProjectCards/Table';
import Pecks from './Components/ProjectCards/Pecks';
import Light from './Components/ProjectCards/WallLight';
import Oval from './Components/ProjectCards/Oval';
import Vessel from './Components/ProjectCards/Vessel';

import Footer from './Components/Footer';
import Navigation from './Components/Navigation';
import Acknowledgement from './Components/Acknowledgement';
import Hedge from './Components/ProjectCards/Hedge';

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      
      <Route path='/yarramundi' element={<Table/>}/>
      <Route path='/seat' element={<Seat/>}/>
      <Route path='/clothespecks' element={<Pecks/>}/>
      <Route path='/walllight' element={<Light/>}/>
      <Route path='/oval' element={<Oval/>}/>
      <Route path='/vessel' element={<Vessel/>}/>
      <Route path='/hedge' element={<Hedge/>}/>
      
    <Route path='/contact' element={<Contact/>}/>
      <Route path='/about' element={<About/>}/>
      <Route exact path='/' element={<Home/>} />
      
    </Routes>
  );
}

function App() {

 
  // When refresh have a transition of acknolegement of traditional owners. keep on whatever page left on https://www.junglefy.com.au/product/junglefy-breathing-stand
  const [showAck, setShowAck] = useState(true);
  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowAck(false)
    });
  }, [])


  return (
    <Router>
      <AnimatePresence>
      {showAck 
        ? <Box sx={{ 
            margin: 0, 
            display: 'flex',
            cursor: `url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2230%22%20zoomAndPan%3D%22magnify%22%20viewBox%3D%220%200%2075%2074.999997%22%20height%3D%2230%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20version%3D%221.0%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20transform%3D%22matrix(0.53033%2C%200.53033%2C%20-0.53033%2C%200.53033%2C%203.495366%2C%201.429346)%22%20fill%3D%22none%22%20stroke-linejoin%3D%22miter%22%20d%3D%22M%202.000795%201.999422%20L%20127.939468%201.999422%20%22%20stroke%3D%22%23385242%22%20stroke-width%3D%224%22%20stroke-opacity%3D%221%22%20stroke-miterlimit%3D%224%22%2F%3E%3Cpath%20stroke-linecap%3D%22round%22%20transform%3D%22matrix(0.53033%2C%20-0.53033%2C%200.53033%2C%200.53033%2C%202.5922%2C%2071.548976)%22%20fill%3D%22none%22%20stroke-linejoin%3D%22miter%22%20d%3D%22M%201.999545%202.00295%20L%20127.934536%201.999267%20%22%20stroke%3D%22%23385242%22%20stroke-width%3D%224%22%20stroke-opacity%3D%221%22%20stroke-miterlimit%3D%224%22%2F%3E%3C%2Fsvg%3E"), auto`,
          }}  >
         <Acknowledgement/>
        </Box>
        : <>
      <Navigation/>
      <Box sx={{ minHeight: '100vh', margin: 0, display: 'flex', flexDirection: 'column', }}>
        <Box sx={{ mt: '80px', flex: 'auto' }}>
            
          <LocationProvider>
            <RoutesWithAnimation />
          </LocationProvider>
        </Box>
        <Box component='footer' sx={{
            py: 2,
            px: 0,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}>
          <Footer />
        </Box>

      </Box>
      </>}
      
      </AnimatePresence>
    </Router>
  );
}

export default App;
