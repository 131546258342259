import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const properties = {
  infinite: true,
  indicators: true,
  arrows: true,
  autoplayInterval: 1000
}
const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  width: '100%'
}

function Slideshow ({slideImages}) {
    return (
      <div >
        <Slide {...properties}>
         {slideImages.map((slideImage, index)=> (
              <div key={index} >
                <img
                src={`${slideImage.url}`}
                alt={`Table${index}`}
                style={divStyle}
                loading='lazy'/>
              </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slideshow;
