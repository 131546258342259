import table from '../Photos/table.JPG';
import chair from '../Photos/chair.png';
import light from '../Photos/light.jpg';
import oval from '../Photos/oval.jpg';
import peck from '../Photos/peck.jpg';
import vessel from '../Photos/vessel.JPG';
import hedge from '../Photos/Hedge.jpg';

const images = [
  {
    id: 1,
    img: table,
    title: 'Yarramundi',
    link: 'yarramundi',
  }, 
  {
    id: 2,
    img: hedge,
    title: 'Travelling Hedge',
    link: 'hedge',
  },
  {
    id: 3,
    img: chair,
    title: 'Claystone',
    link: 'seat',
  },
  {
    id: 4,
    img: peck,
    title: 'Pecks',
    link: 'clothespecks',
  },
 
  {
    id: 5,
    img: light,
    title: 'Snood',
    link: 'walllight',
  },
  {
    id: 6,
    img: oval,
    title: 'Oval',
    link: 'oval',
  },
  {
    id: 7,
    img: vessel,
    title: 'Tides',
    link: 'vessel',
  },
];
    

export default images;