import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Slide.css';
import { Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const arrowStyle = {
  fontSize: {xs: '20px', md: '25px'},
  color: 'black',
  zIndex: 1,
  cursor: 'pointer',
  backgroundColor: 'lightgrey', 
  padding: {xs: '5px', md: '10px'}, 
  borderRadius: '50%', 
  opacity: '0.6'
}
const leftArrowStyle = {
  position: 'absolute',
  bottom: '50%',
  transform: 'translate(0, 50%)',
  left: '15px',
  ...arrowStyle
}

const rightArrowStyle = {
  position: 'absolute',
  bottom: '50%',
  transform: 'translate(0, 50%)',
  right: '15px',
  ...arrowStyle
}

export default function SlideShow2({ images }) {
  const preLoadedImages = useRef([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const timeoutRef = React.useRef(null);



  const goToPrevious = () => {
    const isFirstSlide = currentImageIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentImageIndex - 1
    setCurrentImageIndex(newIndex)
  }

  const goToNext = useCallback(() => {
    const isLastSlide = currentImageIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentImageIndex + 1
    setCurrentImageIndex(newIndex)
  }, [currentImageIndex, images])


  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      goToNext()
    }, 4000);

    return () => {
      resetTimeout();
    };
  }, [goToNext]);

  // PRELOAD IMAGES TO PREVENT FLICKERING
  useEffect(() => {
    preLoadedImages.current = images.map((image) => {
      const img = new Image();
      img.src = image.url;
      return img;
    });

  }, [images]);


  return (<>
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-currentImageIndex * (100)}%, 0, 0)` }}
      >
        {images.map((image, index) => (
          <div
            className="slide"
            key={index}
          >
            <img src={image.url} alt={`${index + 1}`}/>
          </div>
        ))}
      </div>
        <ArrowBackIosNewIcon 
          sx={leftArrowStyle} 
          onClick={goToPrevious} 
        />

        <ArrowForwardIosIcon
          sx={rightArrowStyle}
          onClick={goToNext}
        />
      <div className="slideshowDots">
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${currentImageIndex === idx ? " active" : ""}`}
            onClick={() => {
              setCurrentImageIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  </>);
}
