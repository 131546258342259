import { Box, Hidden, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import theme from '../../Styled Components/Theme'
import { motion as m } from 'framer-motion';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import images from '../images';
import SlideShow2 from '../SlideShow2';
import LeftArrow from '../../Styled Components/LeftArrow';
import RightArrow from '../../Styled Components/RightArrow';

function BasicCard({ project }) {

  const P1Sub = () => {
    return (
      <><div style={{ display: project.p1Sub ? 'block' : 'none'}}>{project.p1Sub}</div></>
    )
  }
  const P2 = () => {
    return (
      <><div style={{ display: project.p2 ? 'block' : 'none'}}><br/>{project.p2}</div></>
    )
  }

  const P3 = () => {
    return (
      <><div style={{ display: project.p3 ? 'block' : 'none'}}><br/>{project.p3}</div></>
    )
  }
  
  const nextProjectIndex = () => {
    var index = project.index + 4
    if (index > 6) {
      const difference = index - 7
      index = 0 + difference;
    }
    return index;
  }

  const prevProjectIndex = () => {
    var index = project.index + 3
    console.log(index);
    if (index > 6) {
      index = index - 7;
    }
    console.log("index", index);
    return index;
  }

  return (
    <>
    <ThemeProvider theme={theme}>
    <m.div
      initial={{
        opacity: 0,
        y: "-10",
      }}
      animate={{
        opacity: 1,
        y: "0",
        transition: {
          duration: 0.5,
          delay: 0.1
        },
      }}
    >
    <Box sx={{ m: 'auto', width: { xs: '85%', sm: '75%', md: '80%'}, pt: { xs: '5%', sm:'3%' }, minHeight: '80vh', pb: {xs: '100px', xl: '10px'}}}>
      <Hidden xlUp>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ m: 'auto', alignItems: 'center'}} direction='row'>
        {/* Main Image */}
          <Grid item md={12} lg={6} display='flex' width='100%' order={{ xs: 2, lg: 1,}}>
            <Box>
              <m.div
                initial={{
                  opacity: 0,
                  y: -100
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  duration: 1,
                  delay: 0.2
                }}
              >
                <img src={project.main} alt="Table Main" width='100%'/>
              </m.div>
            </Box>
          </Grid>

          {/* Text */}
          <Grid item md={12} lg display='flex' order={{ xs: 1, lg: 2,}} width='100%'>
          <Box>
            <m.div
              initial={{
                opacity: 0,
                y: -100
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              transition={{
                duration: 1,
                delay: 0.4
              }}
            >
              <Typography fontWeight={500} fontSize={{ xs: 28, sm: 35, md: 40}} sx={{ pb: '0px' }} color='primary.dark'>{project.title}</Typography>
              <Typography fontSize={{ xs: 18, sm: 20, md: 25}} sx={{ pb: '20px', ml: 2 }} fontWeight={200} color='primary.dark'>{project.subTitle}</Typography>
              <Typography variant="body1" component='h1' textAlign='justify'>
                {project.p1}
                <P1Sub/>
                <P2 />
                <P3/>
              </Typography>
            </m.div>
            </Box>
          </Grid>
        
          {/* SlideShow */}
          <Grid item md={12} display='flex' order={3} width='100%'>
            <Box 
              mt='20px' 
            >
              <m.div
                initial={{
                  opacity: 0,
                  x: -50
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                transition={{
                  duration: 0.7,
                  delay: 0.3
                }}
              >
                <SlideShow2 images={project.slide}/>
              </m.div>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xlDown>
      <Grid container justifyContent='center' alignItems='end' spacing={8} direction='row' mb={3}>
        <Grid item lg={5}>
          <Box>
            <m.div
              initial={{
                opacity: 0,
                y: -100
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              transition={{
                duration: 1,
                delay: 0.6
              }}
            >
              <Box p={2}>
                <Typography fontWeight={500} fontSize={{ xs: 28, sm: 35, md: 40}} color='primary.dark'>{project.title}</Typography>
                <Typography fontSize={{ xs: 18, sm: 20, md: 25}} sx={{ pb: '20px', ml: 2 }} fontWeight={200} color='primary.dark'>{project.subTitle}</Typography>
                <Typography variant="body1" component='h1' fontWeight={300} textAlign='justify' mb={3}>
                  {project.p1}
                  <P1Sub/>
                  <P2 />
                  <P3/>
                </Typography>
              </Box>
            </m.div>
            <m.div
                initial={{
                  opacity: 0,
                  y: -100
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  duration: 1,
                  delay: 0.4
                }}
              >
                <img src={project.main} alt="Table Main" width='100%'/>
              </m.div>
            </Box>
        </Grid>
        <Grid item lg mb={-4}>
          <m.div
                initial={{
                  opacity: 0,
                  x: 100
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                transition={{
                  duration: 1,
                  delay: 0.7
                }}
              >
          <SlideShow2 images={project.slide}/>
          </m.div>
        </Grid>
      </Grid>
      </Hidden>
      </Box>
      {/* Arrows */}
          
      <Box position='absolute' bottom={0} width='100%' mb={1}>
        <Grid container display='flex' 
          direction='row'
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{width: { xs: '85%', sm: '75%', md: '80%'}, m: 'auto', p: '5px'}}
        >
          <Grid item xs={12} sm={6} display='flex'><LeftArrow title={images[(prevProjectIndex())].title} link={images[prevProjectIndex()].link}/></Grid>
          <Grid item xs={12} sm display='flex' justifyContent='right'><RightArrow title={images[nextProjectIndex()].title} link={images[nextProjectIndex()].link}/></Grid>
        </Grid>
      </Box>        
    </m.div>
  </ThemeProvider>
    </>
  )
}

export default BasicCard;