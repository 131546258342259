import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import hedgeMain from '../../Photos/Hedge.jpg'
import Slideshow from "../SlideShow";
import img1 from '../../Photos/HedgeSlide/img1.jpg';
import img2 from '../../Photos/HedgeSlide/img2.jpg';
import img3 from '../../Photos/HedgeSlide/img3.jpg';
import { motion as m } from 'framer-motion';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import BasicCard from "./BasicCard";

export default function Hedge() {
  const hedgeImages = [
    {
      url: img1,
    },
    {
      url: img2,
    },
    {
      url: img3,
    },
  ]

  const paragraph1 = "Elevate your space with the ultimate solution in green interior design and sustainable air filtration. The Travelling Hedge is your breath of fresh air, both figuratively and literally. Its modular design and slender profile allows it to snugly fit into any small office space. Consisting of 72 air-purifying plants, its slender profile doesn't compromise on the lush greenery it brings to your surroundings."
  const paragraph2 = "Easy to assemble and disassemble on-site, our green wall offers flexibility like no other. Need a change of scenery? No problem. Our modular design allows effortless relocation, wherever your inspiration takes you. The water reservoir is refilled by the company on a schedule according to the plants needs. During this time they are able to monitor the plants and replace any which need some extra TLC."

  const hedge = {
    main: hedgeMain,
    slide: hedgeImages,
    title: "Travelling Hedge",
    subTitle: "the Portable, Active Greenwall",
    p1: paragraph1,
    p2: paragraph2,
    p3: '',
    index: 1
  }

  return (<BasicCard project={hedge}/>)
}
