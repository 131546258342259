import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import headshot from '../Photos/headshot.png';
import {motion as m} from 'framer-motion';

function About () {
  return (
    <m.div
      initial={{
        opacity: 0,
        y: "-10",
      }}
      animate={{
        opacity: 1,
        y: "0",
        transition: {
          duration: 0.5,
          delay: 0.1
        },
      }}
    >
    <Box sx={{ 
      m: 'auto',
      width: '90%',
      pt: '5%',
      mb: '2%'
    }}>
      <Typography 
        variant="h2" 
        component='h1' 
        textAlign='left' 
        mb='15px'
        ml='20px'
      >
        About Me
      </Typography>
      <Grid 
        container 
        rowSpacing={1} 
        columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
        sx={{ m: 'auto', width: '90%', alignItems: 'center'}} 
        direction={{ xs: 'column-reverse', md: 'row'}}
      >
        <Grid item xs={12} md={4} display='flex'>
          <Box>
            <img src={headshot} alt="headshot" width='100%'/>
          </Box>
        </Grid>
        <Grid item xs={12} md display='flex'>
          <Box sx={{p: '10px', m: '10px' }}>
            <Typography variant="body1" component='h2'>
            Bianca Smith of studio . B, is studying a bachelor of Industrial design at the University of Canberra, and is set to graduate by the end of 2024. She has a passion for problem-solving specifically related to environmentally conscious design. Most notably, her final project the 'Travelling Hedge', inspired by the design flaws seen in the existing portable active green walls on the Australian market. She hopes to develop her hands-on skills while she works for Workshop 7 next year (2024) as she completes her degree.
            </Typography>
          </Box>
        </Grid>
    </Grid>
  </Box>
  </m.div>
    )
}

export default About;
