import React, { useEffect, useState } from "react";
import { ImageList, ImageListItem, Box, Typography, ThemeProvider, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion as m } from 'framer-motion';
import theme from "../Styled Components/Theme";

const titleStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'black', 
}

const smallContianer = {
  // marginTop: '5px',
  marginBottom: '20px',
}

function Gallery ({itemData}) {

  const [hoveringID, setHoveringID] = useState();
  const setId = (id) => {
    setHoveringID(id);
  }
  const removeId = () => {
    setHoveringID(null);
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [constantShow, setConstantShow] = useState(false);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    if (windowSize[0] < 550) {
      setConstantShow(true);
    } else {
      setConstantShow(false);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  useEffect(() => {
    // Preload images
    itemData.forEach((item) => {
      const img = new Image();
      img.src = `${item.img}?w=248&fit=crop&auto=format`;
      img.onload = () => {
        // The image has been preloaded, you can add any handling logic here
      };
    });

  }, [itemData]);

  const mouseOver = (e) => {
    e.target.style.opacity = '0.2';
  }
  const mouseOut = (e) => {
    e.target.style.opacity = '1';
  }
  const matchDownMd = useMediaQuery(theme.breakpoints.down('xl'));

  const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '80%', height: '100%', m: 'auto', paddingBottom: '20px'}}>
        <ImageList variant="masonry" cols={matchDownXs ? 1 : matchDownMd ? 2 : 3} gap={8}>      
          {itemData.map((item, i) => (
            <Link 
              to={`/${item.link}`} 
              // onMouseOver={() => setId(item.id)} 
              // onMouseLeave={() => removeId()} 
              style={{textDecoration: 'none'
            }}>
              <m.div style={{ position: 'relative'}} 
                whileHover={{
                  scale: 0.97,
                  transition: {
                    duration: 0.3,
                  },
                }}
                onHoverStart={() => setId(item.id)}
                onHoverEnd={() => removeId()}
                initial={{ opacity: 0, translateY: -100 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5, delay: i * 0.05 }}
              >
                <ImageListItem key={item.id} sx={{ textAlign: 'center' }}>
                  {!constantShow && hoveringID === item.id && 
                    <Typography 
                      sx={{ ...titleStyle, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                      fontSize: { xs: '25px', sm: '30px', md: '38px', lg: '60px', xl: '80px' }
                    }}>
                      {item.title}
                    </Typography>
                  }
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onMouseOver={mouseOver}
                    onMouseLeave={mouseOut}
                  />
                  {constantShow && <div style={smallContianer}></div>}
                </ImageListItem>
              </m.div> 
            </Link>          
          ))}
        </ImageList> 
      </Box>
    </ThemeProvider>
  )
}

export default Gallery;
