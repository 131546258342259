import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import ovalMain from '../../Photos/oval.jpg'
import Slideshow from "../SlideShow";
import img1 from '../../Photos/OvalSlide/img1.png';
import img2 from '../../Photos/OvalSlide/img2.jpg';
import img3 from '../../Photos/OvalSlide/img3.jpg';
import img4 from '../../Photos/OvalSlide/img4.png';
import img5 from '../../Photos/OvalSlide/img5.png';
import img6 from '../../Photos/OvalSlide/img6.jpg';
import img7 from '../../Photos/OvalSlide/img7.png';
import { motion as m } from 'framer-motion';
import { Link } from "react-router-dom";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import BasicCard from "./BasicCard";


function Oval () {
  const ovalImages = [
    {
      url: img1,
    },
    {
      url: img2,
    },
    {
      url: img3,
    },
    {
      url: img4,
    },
    {
      url: img5,
    },
    {
      url: img6,
    },
    {
      url: img7,
    },
  ]

  const paragraph1 = "The Oval is a 3 wheel electric share scooter that allows parent and child to ride together on one device. Not excluding young or old from the experience. The Oval allows the child to remain included throughout the ride. Transforming them from a passenger to the pilot."
  const paragraph2 = "The aim of this project was to find a gap in Canberra's shared mobility market.  What addition would the community benefit from? What could be used by tourists and locals? My solution- A 2 person scooter. Designed with the safety of the young passenger as the priority. "

  const oval = {
    main: ovalMain,
    slide: ovalImages,
    title: "Oval",
    subTitle: "the Electric Scooter",
    p1: paragraph1,
    p2: paragraph2,
    p3: '',
    index: 5
  }

  return (<>
    <BasicCard project={oval}/>

  </>)
}

export default Oval;