import { Box } from "@mui/material";
import { BiSolidRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import pageSwitchLink from "./PageSwitch";

function RightArrow({title, link}) {
  return (<>
    <Link to={`/${link}`} style={pageSwitchLink}>
      <Box style={{display: "flex", alignItems: "center"}}>
        <span style={{ fontSize: '25px', marginBottom: '2px', marginRight: '20px'}}>{title}</span><BiSolidRightArrow size={20}/>
      </Box>
    </Link>
  </>)
}

export default RightArrow;