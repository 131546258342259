import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import lightMain from '../../Photos/light.jpg'
import Slideshow from "../SlideShow";
import light1 from '../../Photos/LightSlide/light1.png';
import light2 from '../../Photos/LightSlide/light2.png';
import light3 from '../../Photos/LightSlide/light3.png';
import theme from "../../Styled Components/Theme";
import { motion as m } from 'framer-motion';
import { Link } from "react-router-dom";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import VerticalCard from "./VerticalCard";

function WallLight () {
  const lightImages = [
    {
      url: light1,
    },
    {
      url: light3,
    },
    {
      url: light2,
    },
  ]

  const paragraph1 = 'The floating circles of this wall light create beautifully dynamic shadows and reflections, which project onto the wall behind it. The shiny finish of the carbon fibre, mild steel and brass intensifies those dynamic reflections to make for a perfect bedside wall light. The chromate coating allows the metals to last longer, as it prevents galvanic corrosion, meaning you’ll get the most out of this day and night.'
  const paragraph2 = "This wall light is made using 3 main materials; mild steel, carbon fibre and brass. The aim of this project was to make a corrosion resistant light which uses both an anode and cathode and no paint or anodising. I explored the use of chromate coating for the brass and steel. Overall I designed the anode and cathode to be separate from each other unless galvanized or spaced with rubber fittings."
  const paragraph3 = "To make production easier, I took parts from existing lights and modified them to match my design."

  const light = {
    main: lightMain,
    slide: lightImages,
    title: "Snood",
    subTitle: "the Wall Light",
    p1: paragraph1,
    p2: paragraph2,
    p3: paragraph3,
    index: 4
  }

  return (<>
  <VerticalCard project={light}/>
  </>)
}

export default WallLight;