import React, { useState } from "react";
import { Box, Button, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import theme from "../Styled Components/Theme";

function ContactForm () {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => { 
    event.preventDefault();
    console.log("Form Submitted");
    emailjs.sendForm('service_rk5x0ak', 'template_2dsw5no', event.target, 'MwjDEk8Txl1FoA_GA')
      .then((result) => {
        console.log(result.text);
        alert('Message Sent Successfully');
      }, (error) => {
        console.log(error.text);
        alert('error: Ooops, something went wrong');
      });
    event.target.reset()
  }

  return(<>
  <ThemeProvider theme={theme}>
    <Typography variant="h4" align="center" mb={4}>
      Get In Touch!
    </Typography>
    <form onSubmit={handleSubmit}>
      <Grid 
        container 
        rowSpacing={0} 
        columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
        sx={{ alignItems: 'center'}} 
        direction={{ xs: 'column', md: 'row'}}
      >
        <Grid item xs md={6} display='flex' width='100%'>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            margin="normal"
            required
            variant="filled"
            color="secondary"
          />
        </Grid>
        <Grid item xs={12} md={6} display='flex' width='100%'>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            margin="normal"
            required
            variant="filled"
            color="secondary"
          />
        </Grid>
        <Grid item xs={12} md={12} width='100%'>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            variant="filled"
            color="secondary"
          />
        </Grid>
        <Grid item xs={12} md={12} width='100%'>
          <TextField
            fullWidth
            label="Message"
            value={message}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            variant="filled"
            color="secondary"
          />
        </Grid>
        <Grid item width='100%'>
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Button 
              variant="contained"
              type="submit"
              sx={{ mt: 2,
                '&:hover': {
                  color: 'primary.light',
              },
              }}
          
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
    </ThemeProvider>
  </>)
}

export default ContactForm;