import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import pecksMain from '../../Photos/peck.jpg'
import Slideshow from "../SlideShow";
import pecks1 from '../../Photos/PecksSlide/pecks1.png';
import pecks2 from '../../Photos/PecksSlide/pecks2.jpg';
import { Link } from "react-router-dom";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import { motion as m } from 'framer-motion';

function Pecks () {
  const pecksImages = [
    {
      url: pecks1,
    },
    {
      url: pecks2,
    },
  ]
  return (<>
  <ThemeProvider theme={theme}>
    <m.div
      initial={{
        opacity: 0,
        y: "-10",
      }}
      animate={{
        opacity: 1,
        y: "0",
        transition: {
          duration: 0.5,
          delay: 0.1
        },
      }}
    >
      <Box sx={{ m: 'auto', width: '80%', pt: '3%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ m: 'auto', width: '90%', alignItems: 'center'}} direction={{ xs: 'column-reverse', lg: 'row'}}>
          <Grid item xs={12} lg={4} display='flex'>
            <Box>
              <m.div
                initial={{
                  opacity: 0,
                  y: -50
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  duration: 1,
                  delay: 0.8
                }}
              >
                <img src={pecksMain} alt="Pecks Main" width='100%'/>
              </m.div>
            </Box>
          </Grid>
          <Grid item xs={12} lg display='flex'>
            <Box sx={{p: '10px', m: '10px' }}>
              <m.div
                initial={{
                  opacity: 0,
                  y: -50
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  duration: 1,
                  delay: 0.4
                }}
              >
                <Typography variant="h4" component='h1' sx={{ pb: '20px' }} >PECKS</Typography>
                <Typography variant="h6" component='h2' fontWeight={200}>the Clothes Pegs</Typography>
                <Typography variant="body1" component='h1'>
                  PECKS, wooden pegs in the shape of local birds. No rotting wood that discolours your clothes, no annoying springs that don't stay put and no brittle plastics that fade in the sun. PECKS are made of local timbers by local artisans to bring you a beautifully authentic, long-lasting solution to the fleeting lifespan of your ordinary pegs. Their friendly design breathes life into your apartment, bridging your living space to the outside environment.
                  <br/><br/>
                  This project centered around emotional durability. How do you make a simple household product last for 100 years? I wanted the owner of these pegs to feel a sense of connection through the different birds living in their local area.  Each set of birds that come in your pack of PECKS would depend on your location, or any special birds you request. This adds value to the PECKS in the eyes of the owner, encouraging them to keep them for a lifetime. 
                </Typography>
              </m.div>
            </Box>
          </Grid>
        </Grid>
        <Box width='100%' mt='20px' height='100%'>
          <m.div
            initial={{
              opacity: 0,
              x: -50
            }}
            animate={{
              opacity: 1,
              x: 0
            }}
            transition={{
              duration: 1,
              delay: 0.7
            }}
          >
            <img src={pecks2} alt="Pecks2" width='100%'/>
            {/* <Slideshow slideImages={pecksImages} /> */}
          </m.div>
        </Box>
        <Box mt={3} mb={4}>
          <Box sx={{ display: 'inline-block'}}>
            <Link to='/vessel' style={pageSwitchLink}>
              <Box style={{display: "flex", alignItems: "center"}}>
                <BiSolidLeftArrow size={20}/><span style={{ fontSize: '25px', marginBottom: '2px', marginLeft: '20px'}}>Tides</span>
              </Box>
            </Link>
          </Box>
          <Box sx={{ display: 'inline-block', position: 'relative', float: 'right'}}>
            <Link to='/yarramundi' style={pageSwitchLink}>
              <Box style={{display: "flex", alignItems: "center"}}>
                <span style={{ fontSize: '25px', marginBottom: '2px', marginRight: '20px'}}>Yarramundi</span><BiSolidRightArrow size={20}/>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </m.div>
  </ThemeProvider>
  </>)
}

export default Pecks;