import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#B5D175',
      light: '#E6E6E6',
      dark: '#385242',
    },
    secondary: {
      main: '#4C4C4E',
      light: '#FFFFFF',
    }
  },
  typography: {
    h4: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '30pt'
    },
    h6: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: '18pt'
    },
    body1: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: '14pt'
    }
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      xs: 0,
      sm: 550,
      md: 800,
      lg: 1300,
      xl: 2200
    }
  }

})

export default theme;
