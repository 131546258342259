import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import vesselMain from '../../Photos/vessel.JPG'
import Slideshow from "../SlideShow";
import vessel1 from '../../Photos/VesselSlide/vessel1.png';
import vessel2 from '../../Photos/VesselSlide/vessel2.png';
import { Link } from "react-router-dom";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import { motion as m } from 'framer-motion';
import BasicCard from "./BasicCard";

function Vessel () {
  const tableImages = [
    {
      url: vessel1,
    },
    {
      url: vessel2,
    },
  ]

  const paragraph1 = 'Materials: White Earthenware + Terracotta'
  const paragraph2 = "Evoking the feeling of movement, this vessel defied what we expect from ceramics. The result of marbling the white earthenware and terracotta clay lent itself to the flow of water. Water based clay, a material well known for being used to make vessels, is now represented in a novel way."

  const vessel = {
    main: vesselMain,
    slide: tableImages,
    title: "Tides",
    subTitle: "the Mono Vessel",
    p1: paragraph1,
    p2: paragraph2,
    index: 6
  }

  return (<>
    <BasicCard project={vessel}/>
  </>)
}

export default Vessel;