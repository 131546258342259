import React from "react";
import logo from '../StudioB.png';
import { Email, Instagram, LinkedIn, } from "@mui/icons-material";
import {
  Box, Grid, ThemeProvider, Typography,
} from '@mui/material';
import { Link } from "react-router-dom";
import theme from "../Styled Components/Theme";
import images from './images'

function Footer () {

  const iconStyle = {
    color: 'black',
    padding: '5px'
  }

  return(
  <ThemeProvider theme={theme}>
    <Grid container 
      display='flex' 
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      width='95%'
      m='auto'
    >
      <Grid item xs={12} sm={6} display='flex' justifyContent={{xs: 'center', sm: 'start'}}>
        <Link to="/">
          <img src={logo} alt="logo" width='140px' />
        </Link>
      </Grid>
      
      <Grid item xs={12} sm={6} display='flex' justifyContent={{xs: 'center', sm: 'end'}}>
        <Box textAlign='center'>
          <Box
              sx={{ fontSize: '14px', textTransform: 'uppercase', m: '15px'}} 
            >
            Contact Me
          </Box>
          <Box>

            <a href="https://www.instagram.com/biancas.design.studio/" target="_blank" rel="noreferrer" style={iconStyle}><Instagram/></a>
            <Link to='/contact' style={iconStyle}><Email/></Link>
            <a href="https://www.linkedin.com/in/bianca-smith-303838232/" target="_blank" rel="noreferrer" style={iconStyle} ><LinkedIn /> </a>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} textAlign='center' pt={1} mt={{xs: '0', sm: '-30px'}}>
        <Typography color="textSecondary" variant="subtitle2">
          © Copyright {new Date().getFullYear()}: <b>studio . B</b>
        </Typography>
      </Grid>
    </Grid>
  </ThemeProvider>
  )
}

export default Footer;
