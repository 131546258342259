import React, { useState } from "react";
import {
  Link
} from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Menu,
  MenuItem,
} from '@mui/material';
import logo from '../StudioB.png';
import navLink from '../Styled Components/navLink';
import MenuIcon from "@mui/icons-material/Menu";
import { Gallery } from "react-grid-gallery";

function Navigation () {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [homeShow, setHomeShow] = useState();
  const [aboutShow, setAboutShow] = useState();
  const [contactShow, setContactShow] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    if (windowSize[0] < 700) {
      setHomeShow(false);
      setAboutShow(false);
      setContactShow(false);
    } else if (windowSize[0] < 900) {
      setHomeShow(true);
      setAboutShow(false);
      setContactShow(false);
    } else if (windowSize[0] < 1100) {
      setHomeShow(true);
      setAboutShow(true);
      setContactShow(false);
    } else {
      setHomeShow(true);
      setAboutShow(true);
      setContactShow(true);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  return (
    <Box sx={{ flexGrow: 1, width: '100%', mb: '0px' }}>
      <AppBar 
        position="fixed"
        color='inherit'
        sx={{ height: '80px', mt: '0', top: '0' }}
      >
        <Toolbar>
          <Container component="div" className='Logo' sx={{ ml: '-20px', mt: '10px', width: '40%' }}>
            <Link to="/">
              <img src={logo} alt="logo" width="140px"/>
            </Link>
          </Container>

          {/* Navigation */}
          {homeShow && <Link to="/" style={navLink} >Gallery</Link>}
          {aboutShow && <Link to="/about" style={navLink}>About</Link>}
          {contactShow && <Link to="/contact" style={navLink}>Contact</Link>}

          {!contactShow && 
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            color="inherit"
          >
            <MenuIcon style={{navLink, color: 'black'}} />
          </Button>}
          
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {!homeShow &&
            <Link to="/" style={{display: homeShow, textDecoration: 'none'}}>
            <MenuItem onClick={handleClose} key={Gallery} style={navLink}>
              Gallery
            </MenuItem></Link>}
            {!aboutShow && 
            <Link to="/about" style={{display: aboutShow, textDecoration: 'none'}}>
              <MenuItem onClick={handleClose} style={navLink}>
                About
              </MenuItem></Link>}
            {!contactShow && 
            <Link to="/contact" style={{display: contactShow, textDecoration: 'none'}}>
              <MenuItem onClick={handleClose} style={navLink}>
                Contact
              </MenuItem></Link>}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Navigation;
