import React from "react";
import { Box, Grid, ThemeProvider, Typography, } from '@mui/material';
import ContactForm from "./ContactForm";
import theme from "../Styled Components/Theme";
import {motion as m} from 'framer-motion';
// Fixed when page is resized small
function Contact () {
  return (<>
  <ThemeProvider theme={theme}>
  <m.div
    initial={{
      opacity: 0,
      y: '-100px'
    }}
    animate={{
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: 0.1
      },
    }}
  >

  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="80vh"
    mt={3}
    mb={3}
  >
    <Box
      backgroundColor='primary.light'
      borderRadius={5}
      width='70%'
      p={2}

      pt={5}
      pb={5}
    >
    
    <Grid 
            container 
            direction='row' 
            justifyContent='space-evenly' 
            alignItems='stretch' 
            m='auto' 
            height='100%'
            maxHeight='100%'
          >
              <Grid item xs='auto' p={1} m='auto' width='100%' maxWidth='400' height='100%'>
                <Typography variant="h2" component='h4'>Bianca Smith</Typography>
                <Typography variant="h5" component='h2' mb={2}>Industrial Designer</Typography>
                <Typography variant="subtitle1" component='h2'>Visit my Instagram to see images of my work and inspirations</Typography>
              </Grid>
              <Grid item xs='auto' p={1} m='auto' width='100%'>
                {/* Create an account in EmailJS */}
               <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ maxWidth: 400, mx: "auto", p: 5 }}>
                    <ContactForm />
                  </Box>
                </Box>
              </Grid>
          </Grid> 
          </Box>

  </Box>
  </m.div>
    </ThemeProvider>
  </>)
}

export default Contact;
