import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import Slideshow from '../SlideShow';
import seatMain from '../../Photos/chair.png'
import seat1 from '../../Photos/ChairSlide/seat1.png';
import seat2 from '../../Photos/ChairSlide/seat2.png';
import seat3 from '../../Photos/ChairSlide/seat3.png';
import { Link } from "react-router-dom";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import { motion as m } from 'framer-motion';
import BasicCard from "./BasicCard";

function Seat () {
  const seatImages = [
    {
      url: seat1,
    },
    {
      url: seat2,
    },
    {
      url: seat3,
    },
  ]

  const paragraph1 = 'Group . Project'
  const paragraph1Sub = 'Materials: White earthenware + terracotta and sandstone.'
  const paragraph2 = "Inspired by the look of draped material and cowhide patterns. We wanted to create a seat which wouldn't require glue to stay together. Each element is interlocking; the clay seat platform and the interlocking sandstone legs. The seat can hold at least 70 kg and weighs 8.7kg. We were able to utilise the strengths of each material. That being, the moldability for clay and compression abilities for sandstone."

  const seat = {
    main: seatMain,
    slide: seatImages,
    title: "Claystone",
    subTitle: "the Seat",
    p1: paragraph1,
    p1Sub: paragraph1Sub,
    p2: paragraph2,
    p3: '',
    index: 2
  }


  return (<>
    <BasicCard project={seat}/>
  </>)
}

export default Seat;