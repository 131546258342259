import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import tableMain from '../../Photos/table.JPG'
import Slideshow from "../SlideShow";
import img1 from '../../Photos/TableSlide/img1.png';
import img2 from '../../Photos/TableSlide/img2.png';
import img3 from '../../Photos/TableSlide/img3.png';
import img4 from '../../Photos/TableSlide/img4.png';
import img5 from '../../Photos/TableSlide/img5.png';
import img6 from '../../Photos/TableSlide/img6.png';
import img7 from '../../Photos/TableSlide/img7.png';
import { motion as m } from 'framer-motion';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import pageSwitchLink from "../../Styled Components/PageSwitch";
import theme from "../../Styled Components/Theme";
import BasicCard from "./BasicCard";

function Table () {
  const tableImages = [
    { url: img1, },
    { url: img2, },
    { url: img3, },
    { url: img4, },
    { url: img5, },
    { url: img6, },
    { url: img7, },
  ]

  const paragraph1 = "The Yarramundi low table by studio . B encapsulates the power of a material's ability to tell a story. The intersecting leg is made from offcuts of iron bark sourced from Yarramundi, meaning Storyteller. Honoring a connection to history through memory, imbuing it’s story into the life of this table. The rest of the table is made from australian hoop pine plywood and victorian ash veneer."
  const paragraph2 = "The aim for this project was to design and make a modern contemporary low table which could be sold by the canberra retailer MIKO. Looking at what products they already sold I was able to gain inspiration and determine a gap in the market. Projecting where the contemporary market was heading, I designed around sleek side profiles and muted angles."
  const paragraph3 = ""

  const table = {
    main: tableMain,
    slide: tableImages,
    title: "Yarramundi ‘Storyteller’",
    subTitle: "the Low Table",
    p1: paragraph1,
    p2: paragraph2,
    p3: paragraph3,
    index: 0
  }
  return (<>
  <BasicCard project={table}/>


  {/* <ThemeProvider theme={theme}>
    <m.div
      initial={{
        opacity: 0,
        y: "-10",
      }}
      animate={{
        opacity: 1,
        y: "0",
        transition: {
          duration: 0.5,
          delay: 0.1
        },
      }}
    >
    <Box sx={{ m: 'auto', width: '80%', pt: '3%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ m: 'auto', width: '90%', alignItems: 'center'}} direction={{ xs: 'column-reverse', lg: 'row'}}>
      <Grid item md={12} lg={6} display='flex'>
        <Box>
          <m.div
            initial={{
              opacity: 0,
              y: -50
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            transition={{
              duration: 1,
              delay: 0.8
            }}
          >
            <img src={tableMain} alt="Table Main" width='100%'/>
          </m.div>
        </Box>
      </Grid>
      <Grid item md={12} lg display='flex'>
        <Box sx={{p: '10px', m: '10px' }}>
          <m.div
            initial={{
              opacity: 0,
              y: -50
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            transition={{
              duration: 1,
              delay: 0.4
            }}
          >
            <Typography variant="h4" component='h1' sx={{ pb: '20px' }} >Yarramundi ‘Storyteller’</Typography>
            <Typography variant="h6" component='h2' fontWeight={200}>the Low Table</Typography>
            <Typography variant="body1" component='h1' fontWeight={10}>
            The Yarramundi low table by studio . B encapsulates the power of a material's ability to tell a story. The intersecting leg is made from offcuts of iron bark sourced from Yarramundi, meaning Storyteller. Honoring a connection to history through memory, imbuing it’s story into the life of this table.
            <br/><br/>
            The rest of the table is made from australian hoop pine plywood and victorian ash veneer. 
            <br/><br/>
            The aim for this project was to design and make a modern contemporary low table which could be sold by the canberra retailer MIKO. Looking at what products they already sold I was able to gain inspiration and determine a gap in the market. Projecting where the contemporary market was heading, I designed around sleek side profiles and muted angles. 
            </Typography>
          </m.div>
          </Box>
        </Grid>
      </Grid>
      <Box width='100%' mt='20px' height='100%'>
        <m.div
          initial={{
            opacity: 0,
            x: -50
          }}
          animate={{
            opacity: 1,
            x: 0
          }}
          transition={{
            duration: 1,
            delay: 0.7
          }}
        >
          <Slideshow slideImages={tableImages} />
        </m.div>
      </Box>
      <Box mt={3} mb={4}>
        <Box sx={{ display: 'inline-block'}}>
          <Link to='/clothespecks' style={pageSwitchLink}>
            <Box style={{display: "flex", alignItems: "center"}}>
              <BiSolidLeftArrow size={20}/><span style={{ fontSize: '25px', marginBottom: '2px', marginLeft: '20px'}}>Pecks</span>
            </Box>
          </Link>
        </Box>
        <Box sx={{ display: 'inline-block', position: 'relative', float: 'right'}}>
          <Link to='/walllight' style={pageSwitchLink}>
            <Box style={{display: "flex", alignItems: "center"}}>
              <span style={{ fontSize: '25px', marginBottom: '2px', marginRight: '20px'}}>Snood</span><BiSolidRightArrow size={20}/>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
    </m.div>
  </ThemeProvider> */}
  </>)
}

export default Table;