import React from "react";
// import Gallery from "./Gallery";
import { Box } from "@mui/material";
import images from './images';
import { motion as m} from 'framer-motion';
import Gallery from "./Gallery";

function Home () {

  return(<>
    <m.div
      initial={{
        opacity: 0,
        y: "-100",
      }}
      animate={{
        opacity: 1,
        y: "0",
        transition: {
          duration: 2,
          delay: 0.1
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%', pt: '20px' }}>
        <Gallery itemData={images} />
      </Box>
    </m.div>
  </>)
}

export default Home;
